import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PropTypes from 'prop-types';
import styles from '../ManageJoiningCode.scss';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import StudentsCounting from '../../../../../../components/PlacementTestOnBoardingWizard/AddStudents/StudentsCounting/StudentsCounting';

function PlacesRemaningInSession({
  localizedContent: { manageJoiningCode: content, placementTests: placementTestsContent },
  enabled,
  places,
  error,
  setPlaces,
  setError,
  availableCredits,
  errorResponse,
  placesForUnknownStudents,
  differenceFromMax,
  setDifferenceFromMax
}) {
  useEffect(() => {
    if (places > availableCredits + placesForUnknownStudents) {
      setDifferenceFromMax(places - (availableCredits + placesForUnknownStudents));
      setError(true);
    } else {
      setError(false);
    }
  }, [places]);
  return (
    <div>
      <div className={styles.placesRemaning}>
        <span className={styles.placesRemaningText}>{content.placesRemaningInSession}</span>
        <StudentsCounting
          disabled={!enabled}
          value={places}
          onDecrease={() => {
            setPlaces(prev => (prev === 0 ? 0 : prev - 1));
          }}
          onIncrease={() => {
            setPlaces(prev => prev + 1);
          }}
          hasError={error}
          errorMessage={`${content.notEnoughLicences} ${differenceFromMax} ${content.aditionalLicencesNeeded} (${availableCredits}
              ${content.licencesRemaning}).`}
          disabledClass={styles.disabledColor}
        />
      </div>

      {error && (
        <div className={styles.errorMessageContainer}>
          <span className={styles.buyMoreLicences}>
            {placementTestsContent.placement_test_add_students_page_buy_more_link_text}
          </span>
        </div>
      )}
      {errorResponse && (
        <div className={styles.errorMessageContainer}>
          {' '}
          <span className={styles.errorMessage}>{errorResponse}</span>
        </div>
      )}
    </div>
  );
}

PlacesRemaningInSession.propTypes = {
  localizedContent: PropTypes.object,
  enabled: PropTypes.bool,
  places: PropTypes.number,
  error: PropTypes.bool,
  setPlaces: PropTypes.func,
  setError: PropTypes.func,
  errorResponse: PropTypes.string,
  availableCredits: PropTypes.number,
  placesForUnknownStudents: PropTypes.number,
  differenceFromMax: PropTypes.number,
  setDifferenceFromMax: PropTypes.func
};

export default compose(
  withLocalizedContent('manageJoiningCode', 'placementTests'),
  connect(
    ({ loadPlacementTestsReducer: { codeDetails, errorResponse } }) => ({
      codeDetails,
      errorResponse
    }),
    {}
  )
)(PlacesRemaningInSession);
