import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { orgRoles } from '@oup/shared-node-browser/org';
import SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails from './SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails/SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails';
import SelfRegisteredUserOnboardingOrgSetupWizardFooter from './SelfRegisteredUserOnboardingOrganizationSetupWizardFooter';
import SelfRegisteredUserOnboardingWizardWarningModal from './SelfRegisteredUserOnboardingWizardWarningModal/SelfRegisteredUserOnboardingWizardWarningModal';
import SelfRegisteredUserOnboardingWizardAddStaff from './SelfRegisteredUserOnboardingWizardAddStaff/SelfRegisteredUserOnboardingWizardAddStaff';
import SelfRegisteredUserOnboardingWizardSuccess from './SelfRegisteredUserOnboardingWizardSuccess/SelfRegisteredUserOnboardingWizardSuccess';
import SelfRegisteredUserCreateFirstPlacementTestOrClassModal from './SelfRegisteredUserCreateFirstPlacementTestOrClassModal/SelfRegisteredUserCreateFirstPlacementTestOrClassModal';
import { showSelfSelectRoleModal } from '../../redux/actions/hubUi';
import { HubLayoutConstants } from '../../globals/hubConstants';

const { SECONDARY_SCHOOL } = orgRoles;

function SelfRegisteredUserOnboardingOrganizationSetupWizard({ secondWizardType, showSelfSelectRoleModalAction }) {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isOpenSelfRegisteredUserModal, setIsOpenSelfRegisteredUserModal] = useState(false);
  const {
    PATH_NAMES: { DASHBOARD_PATH }
  } = HubLayoutConstants;
  const [formData, setFormData] = useState({
    name: '',
    countryCode: '',
    primaryEmail: '',
    webAddress: '',
    role: SECONDARY_SCHOOL,
    projectedSize: ''
  });

  return (
    <>
      <Wizard
        footer={
          <SelfRegisteredUserOnboardingOrgSetupWizardFooter
            branch={secondWizardType}
            formData={formData}
            setIsOpenSelfRegisteredUserModal={setIsOpenSelfRegisteredUserModal}
            showSelfSelectRoleModalAction={showSelfSelectRoleModalAction}
          />
        }
      >
        <SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails
          branch={secondWizardType}
          formData={formData}
          setFormData={setFormData}
        />
        <SelfRegisteredUserOnboardingWizardAddStaff branch={secondWizardType} />
        <SelfRegisteredUserOnboardingWizardSuccess
          setIsOpenSelfRegisteredUserModal={setIsOpenSelfRegisteredUserModal}
        />
      </Wizard>
      <SelfRegisteredUserOnboardingWizardWarningModal
        isOpen={isWarningModalOpen}
        closeWarningModal={() => setIsWarningModalOpen(false)}
      />
      <SelfRegisteredUserCreateFirstPlacementTestOrClassModal
        isOpen={isOpenSelfRegisteredUserModal}
        onClose={() => {
          setIsOpenSelfRegisteredUserModal(false);
          showSelfSelectRoleModalAction(false);
        }}
        onNext={() => {
          // It is a temporary solution until we have the backend implemented on the organization creation side, so that there is continuity in the wizard
          window.location.href = DASHBOARD_PATH;
        }}
        wizardType={secondWizardType}
      />
    </>
  );
}

SelfRegisteredUserOnboardingOrganizationSetupWizard.propTypes = {
  secondWizardType: PropTypes.string,
  showSelfSelectRoleModalAction: PropTypes.func
};

export default compose(
  connect(null, {
    showSelfSelectRoleModalAction: showSelfSelectRoleModal
  })
)(SelfRegisteredUserOnboardingOrganizationSetupWizard);
