import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Link as RouterLink } from 'react-router-dom';

import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep.js';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList';
import TextLink from '@oup/shared-front-end/src/components/TextLink';

import { ACCESS_CODE_TYPES } from '@oup/shared-node-browser/constants';
import { ICON_COURSE, ICON_ASSESSMENT, ICON_CLASS } from '@oup/shared-front-end/src/svg/oup';

import styles from './SelfRegisteredUserOnboardingWizardChooseStudentCode.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function SelfRegisteredUserOnboardingWizardChooseStudentCode({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, enterCode: enterCodeContent },
  closeSelfRegisteredWizardAction,
  selectedStudentCodeOption,
  setSelectedStudentCodeOption
}) {
  const { setIsPrimaryButtonDisabled } = useWizard();

  const handleSelectedOption = e => {
    setSelectedStudentCodeOption(e.target.value);
  };

  useEffect(() => {
    setIsPrimaryButtonDisabled(!selectedStudentCodeOption);
  }, [selectedStudentCodeOption]);

  return (
    <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CHOOSE_STUDENT_CODE_CONTAINER">
      <WizardStep titleText={content.self_registered_user_onboarding_wizard_choose_student_code_title}>
        <div className={styles.chooseStudentCodeContainer}>
          <RadioButtonList
            isColumn={false}
            type="outline"
            name="enter-code-options"
            legend="enter code"
            checkedValue={[selectedStudentCodeOption]}
            onChange={handleSelectedOption}
            radioGroup={[
              {
                id: `enter-product-code-radio`,
                label: enterCodeContent.enter_product_code_title,
                subLabel: enterCodeContent.enter_product_code_subtitle,
                value: ACCESS_CODE_TYPES.PRODUCT,
                icon: <ICON_COURSE />
              },
              {
                id: `enter-class-code-radio`,
                label: enterCodeContent.enter_class_code_title,
                subLabel: enterCodeContent.enter_class_code_subtitle,
                value: ACCESS_CODE_TYPES.CLASS,
                icon: <ICON_CLASS />
              },
              {
                id: `enter-placement-test-code-radio`,
                label: enterCodeContent.enter_placement_session_code_title,
                subLabel: enterCodeContent.enter_placement_test_code_subtitle,
                value: ACCESS_CODE_TYPES.PLACEMENT_TEST,
                icon: <ICON_ASSESSMENT />
              }
            ]}
          />
          <TextLink
            component={RouterLink}
            onClick={e => {
              e.preventDefault();
              closeSelfRegisteredWizardAction();
            }}
          >
            {content.self_registered_user_what_to_do_next_continue_to_hub}
          </TextLink>
        </div>
      </WizardStep>
    </div>
  );
}

SelfRegisteredUserOnboardingWizardChooseStudentCode.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func.isRequired,
  selectedStudentCodeOption: PropTypes.string.isRequired,
  setSelectedStudentCodeOption: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'enterCode'))(
  SelfRegisteredUserOnboardingWizardChooseStudentCode
);
