import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import { compose } from 'recompose';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList';

import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import { getContentPreviewRequest } from '../../../redux/actions/contentPreview';
import styles from './PlacementTestLanguageVariation.scss';
import {
  setEditedPlacementTest,
  setPlacementTestProductChanged
} from '../../../redux/reducers/placementTestSessionCreate';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import { OPT_YL, LANGUAGE_VARIATION } from '../utils/constants';
import withLocalizedContent from '../../../language/withLocalizedContent';

function PlacementTestLanguageVariation({
  content,
  sessionName,
  setEditedPlacementTestAction,
  listeningAccentsCodeProp = '',
  languageVariationCodeProp = '',
  placementTestTypeProp = '',
  getContentPreviewRequestAction,
  placementTestProductChangedAction,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const { isLoading, handleStep, setIsPrimaryButtonDisabled } = useWizard();
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [showLanguageVariation, setShowLanguageVariation] = useState(false);
  const [showAccents, setShowAccents] = useState(false);
  const [stepOut, setStepOut] = useState(false);
  const [originalProduct, setOriginalProduct] = useState(undefined);

  const [listeningAccentsCode, setListeningAccentsCodeAction] = useState(listeningAccentsCodeProp);
  const [languageVariationCode, setLanguageVariationCodeAction] = useState(languageVariationCodeProp);
  const [placementTestType, setPlacementTestTypeAction] = useState(placementTestTypeProp);

  const fadeOut = stepOut ? animationStyles.fadeOut : '';

  useEffect(() => {
    getContentPreviewRequestAction({
      source: 'am'
    });
  }, []);

  useEffect(() => {
    if (placementTestType && content && content.length) {
      const selected = placementTestType === OPT_YL ? content[1] : content[0];
      setSelectedProduct(selected);
      setPlacementTestTypeAction(selected.title);
      setOriginalProduct(selected.productid);
    }
  }, []);

  useEffect(() => {
    if (languageVariationCode) setShowLanguageVariation(true);
  }, [languageVariationCode]);

  useEffect(() => {
    if (listeningAccentsCode) setShowAccents(true);
  }, [listeningAccentsCode]);

  useEffect(() => {
    setIsPrimaryButtonDisabled(
      isLoading ||
        !placementTestType ||
        (placementTestType === LANGUAGE_VARIATION.OPT && (!languageVariationCode || !listeningAccentsCode))
    );
  }, [isLoading, placementTestType, languageVariationCode, listeningAccentsCode]);

  const handleCheckboxSelection = e => {
    const selected = content.filter(product => product.title === e.target.value)[0];
    setPlacementTestTypeAction(selected.title);
    setSelectedProduct(selected);

    if (selected && !selected.language_variations.length) {
      setShowLanguageVariation(false);
      setShowAccents(false);
      setLanguageVariationCodeAction('');
      setListeningAccentsCodeAction(OPT_YL);
    } else {
      setShowLanguageVariation(true);
      setShowAccents(false);
      setListeningAccentsCodeAction('');
    }
  };

  const handleLanguageVariationSelection = e => {
    const chosenLanguageVariation = selectedProduct.language_variations.find(
      languageVariation => languageVariation.language_variation_code.toString() === e.target.value.toString()
    );
    setLanguageVariationCodeAction(chosenLanguageVariation.language_variation_code);
    setShowAccents(true);
    setListeningAccentsCodeAction('');
  };

  const handleListeningAccentSelection = e => {
    const chosenLanguageVariation = selectedProduct.language_variations.find(
      languageVariation => languageVariation.language_variation_code.toString() === languageVariationCode
    );
    const choosenListeningAccents = chosenLanguageVariation.listening_accents.find(
      listeningAccent => listeningAccent['assessment_master:package_org_code'] === e.target.value
    );
    setListeningAccentsCodeAction(choosenListeningAccents['assessment_master:package_org_code']);
  };

  const handleNext = () => {
    const placementTest = {
      platform: 'am'
    };
    if (listeningAccentsCode) {
      placementTest.placementTestType = listeningAccentsCode;
      placementTest.listeningAccentsCode = listeningAccentsCode;
    } else placementTest.placementTestType = OPT_YL;
    if (placementTest.placementTestType !== OPT_YL) {
      placementTest.languageVariationCode = languageVariationCode;
    } else {
      placementTest.languageVariationCode = undefined;
    }
    if (selectedProduct && selectedProduct.productid) placementTest.productId = selectedProduct.productid;
    if (selectedProduct && selectedProduct.isbn) placementTest.isbn = selectedProduct.isbn;
    if (selectedProduct && selectedProduct['assessment_master:package_id'])
      placementTest.externalProductId = selectedProduct['assessment_master:package_id'];
    if (originalProduct && originalProduct !== selectedProduct.productid) {
      placementTestProductChangedAction();
    }
    if (placementTestType) {
      setEditedPlacementTestAction(placementTest);
    }
  };

  handleStep(async () => {
    setStepOut(true);
    handleNext();
    await animationTimeout();
  });

  return (
    <div data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_TEST_TYPE_CONTAINER" className={`${fadeOut}`}>
      <WizardStep titleText={`${placementTestsContent.placement_test_language_variation_title} ${sessionName}`}>
        <div className={styles.placementTestLanguageVariationColumn}>
          <div className={styles.horizontalAlign}>
            <RadioButtonList
              type="outline"
              name="placement-type-group-1"
              legend="legend name test 1"
              noShadow
              checkedValue={[placementTestType]}
              radioGroup={content.map((product, index) => ({
                id: `placement-type-radio-${index}`,
                icon: <img src={product.product_image} alt={product.title} />,
                value: product.title
              }))}
              onChange={handleCheckboxSelection}
              hideLabel
            />
          </div>
          <div>
            {showLanguageVariation && selectedProduct && (
              <div>
                <h3>{`${placementTestsContent.use_of_english}`}</h3>
                <RadioButtonList
                  type="outline"
                  name="language-variation-group-2"
                  legend="legend name test 2"
                  noShadow
                  checkedValue={[languageVariationCode]}
                  radioGroup={selectedProduct.language_variations.map((variation, index) => ({
                    id: `language-variation-radio-${index}`,
                    label: placementTestsContent[variation.language_variation_code],
                    value: variation.language_variation_code
                  }))}
                  onChange={handleLanguageVariationSelection}
                  hideLabel={false}
                />
              </div>
            )}
          </div>
          {showAccents && languageVariationCode && selectedProduct && (
            <div>
              <h3>{placementTestsContent.listening_accents}</h3>
              <RadioButtonList
                type="outline"
                name="listening-accent-variation-group-3"
                legend="legend name test 3"
                noShadow
                checkedValue={[listeningAccentsCode]}
                radioGroup={selectedProduct.language_variations
                  .find(
                    languageVariation => languageVariation.language_variation_code.toString() === languageVariationCode
                  )
                  .listening_accents.map((accent, accentIndex) => ({
                    id: `listening-accent-variation-radio-${accentIndex}`,
                    label: placementTestsContent[accent['assessment_master:package_org_code']],
                    value: accent['assessment_master:package_org_code']
                  }))}
                onChange={handleListeningAccentSelection}
                hideLabel={false}
              />
            </div>
          )}
        </div>
      </WizardStep>
    </div>
  );
}

PlacementTestLanguageVariation.propTypes = {
  setEditedPlacementTestAction: PropTypes.func,
  content: PropTypes.object,
  sessionName: PropTypes.string,
  listeningAccentsCodeProp: PropTypes.string,
  languageVariationCodeProp: PropTypes.string,
  placementTestTypeProp: PropTypes.string,
  getContentPreviewRequestAction: PropTypes.func,
  placementTestProductChangedAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, contentPreview }) => ({
      listeningAccentsCodeProp: placementTestSessionCreate.placementTest.listeningAccentsCode,
      languageVariationCodeProp: placementTestSessionCreate.placementTest.languageVariationCode,
      placementTestTypeProp: placementTestSessionCreate.placementTest.placementTestType,
      sessionName: placementTestSessionCreate.placementTestSessionNameValue,
      content: contentPreview.data || contentPreview.results
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest,
      getContentPreviewRequestAction: getContentPreviewRequest,
      placementTestProductChangedAction: setPlacementTestProductChanged
    }
  )
)(PlacementTestLanguageVariation);
