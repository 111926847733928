import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Wizard } from '@oup/shared-front-end/src/components/Wizard';
import { connect } from 'react-redux';
import SelfRegisteredUserOnboardingOrganizationSetupWizard from './SelfRegisteredUserOnboardingOrganizationSetupWizard';
import SelfRegisteredUserOnboardingChooseRoleWizardFooter from './SelfRegisteredUserOnboardingChooseRoleWizardFooter';

import {
  openJoinClassWithCodeModal,
  showSelfSelectRoleModal,
  closeSelfRegisteredWizard
} from '../../redux/actions/hubUi';

import SelfRegisteredUserOnboardingWizardWelcome from './SelfRegisteredUserOnboardingWizardWelcome/SelfRegisteredUserOnboardingWizardWelcome.js';
import SelfRegisteredUserOnboardingWizardChooseRole from './SelfRegisteredUserOnboardingWizardChooseRole/SelfRegisteredUserOnboardingWizardChooseRole.js';
import SelfRegisteredUserOnboardingWizardChooseOrgType from './SelfRegisteredUserOnboardingWizardChooseOrgType/SelfRegisteredUserOnboardingWizardChooseOrgType.js';
import SelfRegisteredUserOnboardingWizardChooseStudentCode from './SelfRegisteredUserOnboardingWizardChooseStudentCode/SelfRegisteredUserOnboardingWizardChooseStudentCode.js';
import SelfRegisteredUserOnboardingChooseRoleWizardHeader from './SelfRegisteredUserOnboardingChooseRoleWizardHeader.js';
import SelfRegisteredUserEnterACodeModal from './SelfRegisteredUserEnterACodeModal/SelfRegisteredUserEnterACodeModal.js';
import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import localStorageWithCache from '../../utils/localStorageWithCache.js';

function SelfRegisteredUserOnboardingChooseRoleWizard({
  isOpen,
  firstName,
  selectRole,
  openJoinClassWithCodeModalAction,
  showSelfSelectRoleModalAction,
  closeSelfRegisteredWizardAction
}) {
  const [chooseRoleWizardCompleted, setChooseRoleWizardCompleted] = useState(false);
  const [isEnterACodeModalOpen, setIsEnterACodeModalOpen] = useState(false);
  const [selectedStudentCodeOption, setSelectedStudentCodeOption] = useState('');
  const { getKey } = localStorageWithCache;
  const { role: selfSelectedRole } = JSON.parse(getKey('selectedRoleData')) || '';

  const enterACodeModalHandleNext = () => {
    setIsEnterACodeModalOpen(false);
    showSelfSelectRoleModalAction(false);
    openJoinClassWithCodeModalAction();
    closeSelfRegisteredWizardAction();
  };
  const [selectedOption, setSelectedOption] = useState('');
  return (
    <div className={wizardStyles.wizardContainer} data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONTAINER">
      <Wizard
        header={<SelfRegisteredUserOnboardingChooseRoleWizardHeader />}
        footer={
          <SelfRegisteredUserOnboardingChooseRoleWizardFooter
            selfSelectedRole={selfSelectedRole}
            closeSelfRegisteredWizardAction={closeSelfRegisteredWizardAction}
            selectedStudentCodeOption={selectedStudentCodeOption}
            setChooseRoleWizardCompleted={setChooseRoleWizardCompleted}
          />
        }
      >
        <SelfRegisteredUserOnboardingWizardWelcome isOpen={isOpen} firstName={firstName} />
        <SelfRegisteredUserOnboardingWizardChooseRole isOpen={isOpen} selectRoleAction={selectRole} />
        {selfSelectedRole === HubLayoutConstants.SELF_SELECT_ROLES[1].key ? (
          <SelfRegisteredUserOnboardingWizardChooseOrgType
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        ) : (
          <SelfRegisteredUserOnboardingWizardChooseStudentCode
            closeSelfRegisteredWizardAction={closeSelfRegisteredWizardAction}
            selectedStudentCodeOption={selectedStudentCodeOption}
            setSelectedStudentCodeOption={setSelectedStudentCodeOption}
          />
        )}
      </Wizard>
      {chooseRoleWizardCompleted && (
        <SelfRegisteredUserOnboardingOrganizationSetupWizard secondWizardType={selectedOption} />
      )}
      <SelfRegisteredUserEnterACodeModal
        isOpen={isEnterACodeModalOpen}
        onClose={() => setIsEnterACodeModalOpen(false)}
        selectedRole={selectRole}
        onNext={enterACodeModalHandleNext}
      />
    </div>
  );
}

SelfRegisteredUserOnboardingChooseRoleWizard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal,
  closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
})(SelfRegisteredUserOnboardingChooseRoleWizard);
