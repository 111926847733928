import React, { useEffect, useState } from 'react';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import { compose } from 'recompose';
import { HUB_SUPPORT_REGISTER_ORGANISATION_URL } from '@oup/shared-node-browser/constants';
import Select from '@oup/shared-front-end/src/components/Select/Select';
import { projectedSize } from '@oup/shared-node-browser/org';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { ICON_ORG_SMALL, ICON_ORG_LARGE } from '@oup/shared-front-end/src/svg/oup/index';
import RadioButtonList from '@oup/shared-front-end/src/components/RadioButtonList';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep/WizardStep';
import actions from '../../../redux/actions';
import styles from './SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import InformationBanner from '../../InformationBanner/InformationBanner';

function SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails({
  localizedContent: {
    selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent,
    countryCodes: countryCodesContent,
    registerOrgComponent: registerOrgComponentContent,
    registerOrgComponent: content
  },
  errors,
  validate,
  submitting,
  success,
  failure,
  formData,
  setFormData
}) {
  const { nextStep } = useWizard();

  const [failureValidationMessage, setFailureValidationMessage] = useState(false);

  const handleChange = key => value => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const handleBlur = key => () => {
    validate({ [key]: formData[key] });
  };

  const handleKeyUp = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value
    });
  };

  useEffect(() => {
    if (success && !submitting) nextStep();
    if (failure && !submitting) setFailureValidationMessage(true);
  }, [success, failure, submitting]);

  const handleSelectedOption = e => {
    setFormData({
      ...formData,
      projectedSize: e.target.value
    });
  };

  return (
    <div
      className={styles.registerOrgContentContainer}
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_STAFF__ROLE_PROVIDES_ORGANIZATION_DETAILS_CONTAINER"
    >
      <WizardStep
        className={styles.orgSetupStep}
        titleText={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_title}
        subtitleText={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_subtitle}
      >
        {failureValidationMessage && (
          <div className="gin-bot3">
            <ValidationMessage state="error">{registerOrgComponentContent.validation_error_message}</ValidationMessage>
          </div>
        )}

        <div className={styles.orgSetupForm}>
          <TextInput
            id="name"
            label={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_name_label}
            placeholder={
              selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_name_placeholder
            }
            value={formData.name}
            required
            onChange={handleChange('name')}
            onKeyUp={handleKeyUp('name')}
            onBlur={handleBlur('name')}
            isValid={typeof errors.name === 'boolean' ? !errors.name : null}
            validationMessage={errors.name ? registerOrgComponentContent.name_error : ''}
          />
          <Select
            id="countryCode"
            name="countryCode"
            label={selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_details_country_label}
            value={formData.countryCode}
            options={[
              {
                value: '',
                text: selfRegisteredUserOnboardingWizardContent.self_registered_user_provides_org_country_placeholder
              },
              ...Object.entries(countryCodesContent).map(([value, text]) => ({ text, value }))
            ]}
            required
            onChange={handleChange('countryCode')}
            onBlur={handleBlur('countryCode')}
            isValid={typeof errors.countryCode === 'boolean' ? !errors.countryCode : null}
            validationMessage={errors.countryCode ? registerOrgComponentContent.country_error : ''}
          />
          <TextInput
            id="primaryEmail"
            name="primaryEmail"
            label={content.organization_primary_email_address}
            labelSub={content.primary_email_description_2}
            placeholder={content.primary_email_placeholder}
            value={formData.primaryEmail}
            required
            autocomplete="email"
            onChange={handleChange('primaryEmail')}
            onKeyUp={handleKeyUp('primaryEmail')}
            onBlur={handleBlur('primaryEmail')}
            isValid={typeof errors.primaryEmail === 'boolean' ? !errors.primaryEmail : null}
            validationMessage={errors.primaryEmail ? registerOrgComponentContent.primary_email_error : ''}
          />
          <div className={`${styles.fieldContainer} ${styles.radioButtonContainer}`}>
            <p>{content.organization_size}</p>
            <p>{content.organization_size_subtitle}</p>
            <RadioButtonList
              type="outline"
              name="orgSizeSelect"
              legend={content.organization_size}
              noShadow
              checkedValue={[formData.projectedSize]}
              radioGroup={[
                {
                  id: 'projectedSizeRegular',
                  label: content.organization_size_option_1,
                  value: projectedSize.REGULAR,
                  icon: <ICON_ORG_SMALL />
                },
                {
                  id: 'projectedSizeLarge',
                  label: content.organization_size_option_2,
                  value: projectedSize.LARGE,
                  icon: <ICON_ORG_LARGE />
                }
              ]}
              onChange={handleSelectedOption}
            />
          </div>
          {formData.projectedSize === projectedSize.LARGE ? (
            <div className={styles.infoBannerContainer}>
              <InformationBanner>
                <div className={styles.messageContainer}>
                  <p>{content.large_org_info}</p>
                  <TextLink to={content.org_limit_support_link} target="_blank">
                    {content.learn_more_about_limits}
                  </TextLink>
                </div>
              </InformationBanner>
            </div>
          ) : null}
        </div>
      </WizardStep>
      <div className={styles.asideContainer}>
        <h3>{content.aside_heading}</h3>
        <p>{content.aside_content}</p>
        <TextLink to={HUB_SUPPORT_REGISTER_ORGANISATION_URL} target="_blank">
          {content.aside_link_label}
        </TextLink>
      </div>
    </div>
  );
}

SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  validate: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  success: PropTypes.bool,
  failure: PropTypes.bool,
  formData: PropTypes.shape({
    name: PropTypes.string,
    countryCode: PropTypes.string,
    primaryEmail: PropTypes.string,
    projectedSize: PropTypes.string
  }),
  setFormData: PropTypes.func
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'countryCodes', 'registerOrgComponent'),
  connect(
    state => ({
      ...pick(state.orgRegistration, ['errors', 'submitting', 'success', 'failure'])
    }),
    dispatch => ({
      validate: input => {
        dispatch(actions.validateOrgInput(input));
      }
    })
  )
)(SelfRegisteredUserOnboardingWizardNewAdminProvidesOrganizationDetails);
